/* eslint-disable prettier/prettier */
<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="container">
      <div class="fr-col-12 fr-col-lg-6 bloc-gauche">
        <div class="fr-fi-user-line"></div>
        <div class="mt-0 fs-b fs-2500 text-white">Pas de compte ?</div>
        <div class="mt-0 text-white">
          Créez un compte pour accéder à tous les services en ligne.
        </div>
        <div class="crer-compte-ui">
          <a
            class="btn-creer"
            href="/ms/creation-compte"
            @click="tagLogin('creation_compte')"
          >
            Créer un compte
          </a>
        </div>

        <div class="text-ou">
          <span class="divider line one-line text-white"> Ou </span>
        </div>
        <div class="mt-0 text-white">
          utilisez un compte existant<Info
            href="#"
            data="Lorsque vous cliquerez sur un bouton ci-dessous, vous serez redirigé vers la portail tiers afin de saisir vos identifiants de connexion. A la validation de ceux-ci, le formulaire pré-rempli de création de compte portailpro vous sera présenté, vous pourrez le compléter. A la validation de ce dernier, votre compte portailpro sera créé et rattaché à votre compte d’origine."
            cssClass="fr-fi-information-line ml-3 info a-info"
          />
        </div>

        <div class="site-container" role="list">
          <u>
            <li style="margin: 0px 0 5px 0">
              <a
                role="listitem"
                href="/ms/enrolement?id-copro=urssaf"
                aria-label="Connectez-vous en utilisant votre compte Urssaf"
                @click="tagLogin('compte_urssaf')"
                ><div class="site-box">
                  <img
                    class="icon"
                    src="../assets/img/urssaf_mini.svg"
                    alt="mini logo urssaf"
                  />
                  <div class="site-text">urssaf.fr</div>
                </div></a
              >
            </li>
            <li style="margin: 0px 0 5px 0">
              <a
                role="listitem"
                href="/ms/enrolement?id-copro=dgfip"
                aria-label="Connectez-vous en utilisant votre compte Dgfip"
                @click="tagLogin('compte_dgfip')"
                ><div class="site-box">
                  <img
                    class="icon"
                    src="../assets/img/dgfip_mini.svg"
                    alt="mini logo de la DGFIP"
                  />
                  <div class="site-text">impots.gouv.fr</div>
                </div></a
              >
            </li>
            <li style="margin: 0px 0 5px 0">
              <a
                role="listitem"
                href="/ms/enrolement?id-copro=dgddi"
                aria-label="Connectez-vous en utilisant votre compte Douane"
                @click="tagLogin('compte_dgddi')"
                ><div class="site-box">
                  <img
                    class="icon"
                    src="../assets/img/douane_mini.svg"
                    alt="mini logo de la douane"
                  />
                  <div class="site-text">douane.gouv.fr</div>
                </div></a
              >
            </li>
            <li style="margin: 0px 0 5px 0">
              <a
                role="listitem"
                href="/ms/enrolement?id-copro=net_e"
                aria-label="Connectez-vous en utilisant votre compte Net-entreprises"
                @click="tagLogin('compte_net_e')"
                ><div class="site-box">
                  <img
                    class="icon"
                    src="../assets/img/net-entreprise_mini.svg"
                    alt="mini logo de net-entreprise"
                  />
                  <div class="site-text">net-entreprises.fr</div>
                </div></a
              >
            </li>
          </u>
        </div>
      </div>

      <div class="fr-col-12 fr-col-lg-6 bloc-droite">
        <h4 class="ui header connexion">Connexion</h4>
        <div class="ui asterisque">* Champs obligatoires</div>
        <form
          class="ui large form"
          @submit="checkForm"
          method="post"
          id="loginForm"
          :action="getUrlAuth()"
        >
          <div
            v-if="
              (authFailureMsg && authFailureMsg.length !== 0 && checkAuthKO) ||
              msgErreur
            "
            class="fr-alert fr-alert--error ri-alert-line"
          >
            <p class="fr-alert__title">{{ msgErreur }}</p>
          </div>

          <div class="field">
            <div class="ui fluid left icon input">
              <label for="usernameUserInput" class="usernameUserLabel"
                >Identifiant (adresse courriel nominative) *
              </label>
              <input
                type="email"
                autocomplete="username"
                id="usernameUserInput"
                v-model="email"
                value=""
                class="fr-input"
                name="username"
                required="true"
                @input="validateEmail()"
              />
            </div>
          </div>

          <div class="field">
            <div class="ui fluid left icon input">
              <div class="ui password">
                <label for="password" class="passwordLabel">
                  Mot de passe *
                </label>
                <div class="buttons">
                  <div class="field forgot">
                    <a id="passwordRecoverLink" href="/ms/mot-de-passe-oublie">
                      Mot de passe oublié ?
                    </a>
                  </div>
                </div>
              </div>
              <input
                :type="passwordFielType"
                id="password"
                name="password"
                v-model="password"
                value=""
                class="fr-input password"
                required="true"
              />
              <span
                v-show="showMdp"
                class="ri-eye-off-line input-eye"
                @click="showPassword()"
                @keydown.enter="focusPassword()"
                title="Cacher le mot de passe"
                tabindex="0"
                id="cacher"
              />
              <span
                v-show="!showMdp"
                class="ri-eye-line input-eye"
                @click="showPassword()"
                @keydown.enter="focusPassword()"
                title="Afficher le mot de passe"
                tabindex="0"
                id="fermer"
              ></span>
            </div>
          </div>

          <div>
            <input
              type="hidden"
              name="sessionDataKey"
              :value="sesssionDataKey"
            />
          </div>

          <div class="connexion">
            <button
              type="submit"
              class="btn-connexion"
              role="button"
              value="Submit"
            >
              Connexion
            </button>
          </div>
          <div class="renvoyerEmail" v-if="compteNonActive">
            <span
              >Vous n'avez pas reçu de courriel de confirmation ?
              <button
                class="button-echec"
                @click="renvoyer()"
                alt="Renvoyer un courriel de confirmation"
              >
                Renvoyer
              </button></span
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Info from '../shared/Info';
import { mapActions } from 'vuex';
import { EVENT_ACCES_RAPIDE } from '../constantes';
import { sendTag } from '../utils/methodsUtils';

export default {
  components: {
    Info
  },
  data() {
    return {
      showMdp: false,
      passwordFielType: 'password',
      msgErreur: '',
      checkAuthKO: null, //détermine si on vient de valider le formulaire
      password: '',
      email: '',
      sesssionDataKey: this.$route.query?.sessionDataKey,
      authFailureMsg: this.$route.query?.authFailureMsg,
      compteNonActive: false,
      failedUsername: this.$route.query.failedUsername
    };
  },
  mounted() {
    this.compteNonActive = false;
    if (this.authFailureMsg && this.authFailureMsg.length != 0) {
      this.checkAuthKO = true; // l'authentification est KO
      if (this.authFailureMsg == 'login.fail.message') {
        this.msgErreur = `Connexion échouée. Merci de vérifier l'identifiant et le mot de passe et réessayer.`;
      } else if (this.authFailureMsg == 'account.confirmation.pending') {
        this.msgErreur = `Le compte n'est pas vérifié. Un lien d'activation de compte a été envoyé à votre adresse courriel enregistrée, veuillez vérifier votre boîte de réception.`;
        this.compteNonActive = true;
      } else {
        this.msgErreur = '';
      }
    }
    this.emitter.emit(EVENT_ACCES_RAPIDE, this.$route.name);
  },
  methods: {
    tagLogin(value) {
      sendTag('Login', value, '', '');
    },
    validateEmail() {
      this.msgErreur = '';
      if (this.email && this.email.length !== 0) {
        if (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            this.email
          )
        ) {
          this.checkAuthKO = null; //réinitialisé pour une nouvelle authentification
          return true;
        } else {
          this.msgErreur =
            "L'adresse courriel n'est pas au bon format : exemple@domaine.com";
          return false;
        }
      }
    },
    showPassword() {
      this.showMdp = !this.showMdp;
      this.passwordFielType =
        this.passwordFielType === 'password' ? 'text' : 'password';
    },
    focusPassword() {
      this.showPassword();
      setTimeout(() => {
        if (this.showMdp == false) {
          document.getElementById('fermer').focus();
        } else document.getElementById('cacher').focus();
      }, 0);
    },
    getUrlAuth() {
      return `${process.env.VUE_APP_DNS_WSO2}/commonauth`;
    },
    renvoyer() {
      const compte = {
        user: {
          username: this.failedUsername,
          realm: ''
        },
        properties: []
      };
      this.$compteUtilisateurService
        .resendCode(compte)
        .then((res) => {
          if (res.status === 200 && res.data.resultat === 201) {
            const html = `<p class="fs-875">Nous vous avons envoyé un courriel de confirmation.<br><br>
Merci de le consulter afin de finaliser votre inscription.</p>`;
            this.setContent({ html, action: 'info' });
            this.showPopup();
            this.emitter.once('popup', (payload) => {
              if (payload === 'ok' || payload === 'hide') {
                this.$router.push({ name: 'Votre compte' }).catch(() => {});
              }
            });
          } else {
            console.log('erreur envoi courriel confirmation');
          }
        })
        .catch((err) => {
          console.error('erreur', err);
        });
    },
    checkForm: function (e) {
      this.msgErreur = '';
      const validateEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.email
      );
      const validateMdp = this.password && this.password.length !== 0;

      if (validateEmail && validateMdp) {
        return true;
      } else {
        this.msgErreur =
          "Connexion échouée. Merci de vérifier l'identifiant et le mot de passe et réessayer";
        e.preventDefault();
      }
    },
    ...mapActions(['showPopup', 'setContent'])
  }
};
</script>

<style lang="scss" scoped>
.ui.asterisque {
  width: 177px;
  height: 18px;
  font-family: Marianne;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6a6a;
  text-align: left;
  padding-bottom: 4rem;
}
.ui.header.connexion {
  width: 168px;
  margin: 0 286px 10px 0;
  font-family: Marianne;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $base-color;
  text-align: left;
}
.site-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3%;
  padding-bottom: 1%;
}
.site-box {
  border-radius: 2px;
  width: 220px;
  height: 40px;
  padding: 8px 39px 9px 15px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}
ul,
li {
  list-style: none;
  margin: 10px 0 15px 0;
  padding: 0;
}
h1 {
  color: $base-color;
  display: flex;
}

.fs-2500 {
  font-size: 32px;
}

.text-white {
  color: white;
  text-align: center;
}

.connexion {
  display: flex;
  justify-content: center;
  padding-top: 9%;
}

.renvoyerEmail {
  display: flex;
  justify-content: center;
  padding-top: 9%;
}

.bloc-gauche {
  display: grid;
  background-color: $base-color;
  width: 551px;
  min-height: 654px;
  max-width: 551px;
}

.bloc-droite {
  width: 50%;
  max-width: 100%;
  display: block;
  padding: 72px 50px 39px 130px;
}

.fr-fi-user-line::before {
  justify-content: center;
  display: flex;
  color: white;
  padding-bottom: 2%;
  width: 100%;
  margin-top: 10%;
  font-size: 2rem;
}

.container {
  display: flex;
  border: 1px solid #dddddd;
  margin-left: 60px !important;
  margin-bottom: 2%;
  --bs-gutter-x: 0;
  max-width: 1104px !important;
}

.ui.password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-creer {
  border: 0;
  height: 56px;
  margin: 3rem 5rem;
  padding: 18px 32px;
  background-color: #fff;
  font-family: Marianne;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $base-color;
  border-radius: 0px;
  background-image: none;
}

.btn-creer:hover {
  background-color: #e7e7e7 !important;
}

.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
}

.a-info::before {
  background-color: white;
}

#passwordRecoverLink {
  color: $base-color;
}

.input-eye {
  width: 19px;
  height: 16px;
  bottom: 30px;
  right: -90%;
  position: initial;
  margin-left: 90%;
  margin-top: -30px;
  color: $base-color;
  z-index: 9999;
  display: flex;
}

@supports (background: -webkit-named-image(i)) {
  .password:focus + .input-eye {
    margin-left: 82%;
    right: -82%;
  }
}

.crer-compte-ui {
  display: flex;
  justify-content: center;
}

.btn-connexion {
  width: 170px;
  height: 56px;
  background-color: $base-color;
  font-family: Marianne;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #ffffff;
  border: none;
}

.button-echec {
  background: white;
  color: black;
  text-decoration: underline;
}

.btn-connexion:hover {
  background-color: #1212ff !important;
}

.buttons {
  width: 100%;
  text-align: end;
  padding-bottom: 2%;
}

.line {
  align-items: center;
  margin: 1em -1em;
}
.divider {
  font-family: Marianne;
  display: flex;
  font-size: 1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  padding-left: 14%;
  padding-right: 14%;
}
.line:before,
.line:after {
  height: 1px;
  margin: 0 1em;
}
.one-line:before,
.one-line:after {
  background: white;
}
.divider:before,
.divider:after {
  content: '';
  flex: 1;
}
.site-box > img {
  width: 19px;
  height: 20px;
  margin: 3px 10px 0 0;
  object-fit: contain;
}
.site-text {
  color: #2a2a2a;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  text-align: center;
  font-family: Marianne;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.site-box:hover {
  background-color: #e7e7e7;
}

.ui.password {
  width: 100%;
  display: flex;
  padding-top: 12%;
}
.field {
  padding-top: 2%;
}

.usernameUserLabel {
  text-align: initial;
  padding-bottom: 1%;
}

.passwordLabel {
  text-align: initial;
  width: 50%;
  padding-bottom: 1%;
}

@media only screen and (max-width: 760px) {
  .container {
    display: block;
    margin-left: 0px !important;
  }

  .bloc-gauche {
    width: 100%;
  }

  .btn-creer {
    margin: 0rem 0rem;
  }

  .text-white {
    margin: 0rem 2rem;
  }

  .divider {
    padding-left: 0%;
    padding-right: 0%;
  }

  .one-line {
    margin: 0rem 0rem;
  }

  .site-container {
    width: 100%;
  }

  .a-info::before {
    margin-bottom: 0.2rem;
  }

  .bloc-droite {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
  }

  .ui.asterisque {
    padding-bottom: 2rem;
  }

  .usernameUserLabel {
    font-size: 0.88rem !important;
  }

  .ui.password {
    padding-top: 8%;
  }

  .passwordLabel {
    width: 70%;
    font-size: 0.88rem !important;
    margin-top: 0.5rem;
  }

  #passwordRecoverLink {
    font-size: 0.9rem;
  }

  .connexion {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
</style>
